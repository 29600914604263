@media (min-width: 768px) {
    .app-root {
        width: 720px;
        margin: auto;
        position: absolute;
        border-radius: 10px;
        margin-top: 5px;
        margin-bottom: 5px;

    }
}

body {
    background: #1e2c3c;
}

.app-root {
    background: #ffffff;
}

.camera-area {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    border-style: dotted;
    border-radius: 20px;
    margin: 13px;
    border-width: 2px;
}

.camera-area-no-border {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    border-style: dotted;
    border-radius: 20px;
    margin: 13px;
    border-width: 0;
}