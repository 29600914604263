.loading-page-ion-spinner {
    transform: scale(3);
    stroke: #ff0000;
    fill: #8b0000;
    position: fixed;
    z-index: 999;
    height: 5em;
    width: em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}